body {
  color: #1a1a1a;
}

body,
ul,
header {
  margin: 0;
  padding: 0;
}

.equalHW {
  flex: 1;
}

button:disabled,
button[disabled] {
  border: 1px solid #fad3d3;
  background-color: #cccccc;
  color: #666666;
}

.eq {
  padding: 10px;
}

.navbar {
  transition: 850ms;
}

.navbarOpen {
  right: "-100%";
}

.navbar__toggle {
  /* background-color: #060b26; */
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* check */

.user__info img {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  object-fit: cover;
}

.user__info {
  display: flex;
}

.label-text {
  margin-top: -8px;
  margin-left: 15px;
  background: white;
  padding: 0px 2px;
}

.input-box {
  margin-top: 2rem;
  border: 1px solid #0a459f;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  overflow: hidden;
}

/* .box {
  box-shadow: 0px 0px 0px 2px #0a459f 19%;
} */

.footer-text {
  font-size: 0.58rem;
  letter-spacing: 0.2rem;
}

.slim-border {
  border: 0.5px solid #0a459f;
}

.btn-border {
  border: 1.5px solid #0a459f;
}

br {
  display: none;
}

.nav-links {
  font-size: 12px;
}

.height {
  height: 0.15rem;
}

.outline {
  outline: none;
}

.circle {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 1px solid #0a459f;
}

.picture {
  margin-top: 4.28rem;
}

.private {
  margin-top: 30rem;
}

.slim-ash-border {
  border: 0.5px solid #808080;
}

.picture-border {
  border: 0.14px solid #808080;
}

.meeting-icon {
  width: 12%;
}

.lineHeight {
  line-height: 1.2;
}

.lightAsh {
  color: #1a1a1a;
}

.footerText {
  font-size: 0.85rem;
}

.track {
  letter-spacing: 0.2rem;
}

.learn {
  margin-left: 2.7rem;
}

.playStore {
  width: 51%;
}

.visibility {
  margin-left: 23rem;
}

.easily {
  margin-top: 40rem;
}

.services-width {
  width: 90%;
}

@media screen and (min-width: 768px) {
  .m-top {
    margin-top: -35rem;
  }

  .services-width {
    width: 98%;
  }

  .flexReason {
    display: flex;
  }

  .flexItem {
    flex-grow: 0;
    flex-basis: auto;
  }
}

@media screen and (min-width: 1024px) {
  .nav-links {
    font-size: 13px;
  }

  .m-top {
    margin-top: -0;
  }

  .button {
    margin-left: 32rem;
  }

  .inner-picture {
    margin-right: -4.61rem;
  }

  .line {
    line-height: 3.5rem;
  }

  .career-width {
    width: 48%;
  }

  .easily {
    margin-top: 5rem;
  }

  br {
    display: block;
  }

  .playStore {
    width: 42%;
  }

  .services-width {
    width: 100%;
  }
}

@tailwind base;
@tailwind components;

@font-face {
  font-family: "Bw Nista Geometric DEMO";
  src: url("../assets/fonts/bwnistageometricdemoregular-webfont.woff");
}

@font-face {
  font-family: "Bw Nista Geometric DEMO Bold";
  src: url("../assets/fonts/bwnistageometricdemomedium-webfont.woff");
}
/* Bw Nista Geometric DEMO Thin */
@font-face {
  font-family: "Bw Nista Geometric DEMO Thin";
  src: url("../assets/fonts/bwnistageometricdemothin-webfont.woff");
}

@tailwind utilities;
